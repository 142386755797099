import axios from "axios";

const getDefaultState = () => {
  return {
    loading: false,
    finally: false,
    responsibilty: {
      order: {
        desc: "order",
        value: "",
      },

      day: {
        desc: "day",
        value: "",
      },
      teacher: {
        desc: "teacher",
        value: "",
      },
      class: {
        desc: "class",
        value: "",
      },
      edu_class: {
        desc: "educitional class",
        value: "",
      },
      subject: {
        desc: "subject",
        value: "",
        id: null,
      },
      room: {
        desc: "room",
        value: "",
      },
      floor: {
        desc: "floor",
        value: "",
      },
    },
    tableOptions: {
      tableHeaders: [],
      tableData: [],
      accessTableData: [],
      loading: false,
      tableMeta: {
        total: 10,
        page: 1,
      },
    },
  };
};
const state = getDefaultState();

const getters = {
  getTableOptions(state) {
    return state.tableOptions;
  },
  getResponsibiltyData(state) {
    return state.responsibilty;
  },
  getFinallyStatus(state) {
    return state.finally;
  },
  getLoadingStatus(state) {
    return state.loading;
  },

  getTableLoading(state) {
    return state.tableOptions.loading;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setFinallyStatus(state, status) {
    state.finally = status;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },

  setResponsibiltyInformation(state, responsibiltyInfo) {
    state.responsibilty.class.value = responsibiltyInfo.grade.name;
    state.responsibilty.edu_class.value = responsibiltyInfo.edu_class.name;
    state.responsibilty.subject.value = responsibiltyInfo.subject.name;
    state.responsibilty.subject.id = responsibiltyInfo.subject.id;
    state.responsibilty.order.value = responsibiltyInfo.order;
    state.responsibilty.day.value = responsibiltyInfo.day;
    state.responsibilty.teacher.value = responsibiltyInfo.teacher.name;
    state.responsibilty.room.value = responsibiltyInfo.edu_class.room.name;
    state.responsibilty.floor.value =
      responsibiltyInfo.edu_class.room.floor.name;
  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchResponsibilities({ commit }, payload) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }
      const response = await axios.get("/responsibility", {
        params,
      });
      console.log("responsibilityresponsibility", response);
      let tableHeaders = [];
      let accessTableData = [];
      tableHeaders = [
        "id",
        "teacher",
        "class",
        "education class",
        "subject",
        "day",
        "order",
        "settings",
      ];
      accessTableData = [
        ["id"],
        ["teacher", "name"],
        ["grade", "name"],
        ["edu_class", "name"],
        ["subject", "name"],
        ["day"],
        ["order"],
      ];
      commit("setTableData", {
        tableHeaders,
        tableData: response.data.responsibilities,
        accessTableData,
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchResponsibility({ commit }, resId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      commit("getFinallyStatus", false);
      const response = await axios.get(`/responsibility/${resId}`);
      console.log("reg record faaam responsibility", response);
      let responseData = response.data.data;

      commit("setResponsibiltyInformation", responseData);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
      commit("getFinallyStatus", true);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
