import axios from 'axios'
import i18n from '@/i18n';

const state = {
  options: {},
  serverTotal: 1,
  data: [],
  url: '/hr/salary-payment',
  loading: false,
  payment: null,
  page: 1,
}
const getters = {
  data(state) {
    return state.data
  },
  isThereCalculation(state) {
    return state.payment
  },
  paymentData(state) {
    return (state.payment?.calculate_groups ?? []).reduce((prev, curr) => [...prev, ...[...curr.calculate].map(el => ({
      ...el,
      salary: el.final_salary
    }))], [])
  },
  
  paymentHeaders(state) {
    return [...state.paymentHeaders]
  }
}
const mutations = {}
const actions = {
  deleteOldPayments() {
    state.payment = null;
  },
  async fetch({commit}, queryParams) {
    try {
      state.loading = true
      const filterTemp = {};
        Object.keys(queryParams).forEach((key) => {
          if (queryParams[key]) filterTemp[key] = queryParams[key];
        });
        let params = {...filterTemp}
      const response = await axios.get(`${state.url}`, {
        params,
      });
      state.data = response.data.data ?? [];
        // .map(el => ({
        //   ...el, ...((el.details ?? []).reduce((prev, curr) => {
        //     prev[curr.key] = curr.value
        //     return prev
        //   }, {}))
        // }))
      state.serverTotal = response.data.meta.total;
      state.page = response.data.meta.current_page;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async restorePayment({commit, dispatch}, form) {
    try {
      state.loading = true
      let params = {
        ...form,
        paginated: false,
      };
      const response = await axios.get('/hr/calculate', {
        params
      });
      state.payment = response.data.data.map((el)=>({...el,selected: false,value: el.final_salary-el.given_salary}));
      console.log(state.payment);
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  setPayment({}, payment) {
    state.payment = payment
  },
  async submitPaymentsAction({commit, dispatch}, data) {
    try {
      state.loading = true
      const response = await axios.post(`${state.url}`, data);
      return response;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
